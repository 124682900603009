import { Link } from '@remix-run/react';
import { useEffectOnce } from 'react-use';

import { useOnboardingAnalytics } from '../analytics/onboarding';
import { useConfettiAnimation } from '../components/ConfettiAnimation';
import {
  OnboardingModalLayout,
  OnboardingPageLayout,
} from '../components/Onboarding/OnboardingLayout';
import { useMyOrganizer } from '../components/Organization';
import { getStaticAssetPath } from '../utils/assets';

function Container() {
  const organizer = useMyOrganizer();
  const analytics = useOnboardingAnalytics();

  const { fire, canvasConfetti } = useConfettiAnimation();

  useEffectOnce(() => {
    fire();
  });

  return (
    <OnboardingPageLayout progress={90}>
      <OnboardingModalLayout className='gap-10'>
        {canvasConfetti}

        <audio
          src={getStaticAssetPath('audios/v2/spotlight-confetti.mp3')}
          autoPlay={true}
        />

        <h2 className='text-2xl font-medium text-tertiary'>
          High five {organizer?.firstName}! You’re all set up!
        </h2>
        <img
          src={getStaticAssetPath('images/high-five.png')}
          className='w-25 h-25'
          alt='high-five'
        ></img>
        <p className='font-bold px-8 text-center'>
          What’s next? You can customize your Slack programs to work exactly how
          you want them to, or visit the Library to see the available
          experiences for your team!
        </p>
        <div className='flex justify-center items-center gap-5'>
          <Link
            to='/home'
            onClick={() =>
              analytics.trackOnboardingCompletedButtonClicked({
                key: 'library',
              })
            }
            className='w-60 h-15 btn-secondary flex justify-center items-center'
          >
            See the Library
          </Link>
          <Link
            to='/channels'
            onClick={() =>
              analytics.trackOnboardingCompletedButtonClicked({
                key: 'programs',
              })
            }
            className='w-60 h-15 btn-primary flex justify-center items-center'
          >
            Edit My Programs
          </Link>
        </div>
      </OnboardingModalLayout>
    </OnboardingPageLayout>
  );
}

export function Component() {
  return <Container />;
}
